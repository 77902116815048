import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations, OrthographicCamera } from '@react-three/drei';
import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';

import { LinearEncoding, sRGBEncoding } from 'three/src/constants';
import { LineBasicMaterial, MeshPhysicalMaterial, Vector2 } from 'three';
import ReactAudioPlayer from 'react-audio-player';

import createAnimation from './converter';
import blinkData from './blendDataBlink.json';

import * as THREE from 'three';
import axios from 'axios';

import { useAudioRecorder } from 'react-audio-voice-recorder';

import './App.css';

const _ = require('lodash');

const host = process.env.REACT_APP_BACKEND_URL;

function Avatar({ avatar_url, speak, setSpeak, audio, resetAudio, text, setText, callbackTextSent, setAudioSource, playing}) {

  let model = useGLTF(avatar_url);
  console.log(model)
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;

  const [
    hairColorText,
    hairAlphaText,
    hairNormalText,
    teethLowerColor,
    teethNormal,
    teethRoughness,
    bodyBase,
    bodyNormal,
    bodyRoughness,
    bodySpecular,
    eyesTexture
  ] = useTexture([
    "/images/textures/Katheryn.001_hair2Haircards_base_color.png",
    "/images/textures/Katheryn.001_hair2Haircards_alpha.png",
    "/images/textures/Katheryn.001_hair2Haircards_normal.png",
    "/images/textures/Katheryn.001_lower_teeth_base_color.png",
    "/images/textures/Katheryn.001_upper_teeth_normal.png",
    "/images/textures/Katheryn.001_lower_teeth_roughness.png",
    "/images/textures/Katheryn.001_body_base_color.png",
    "/images/textures/Katheryn.001_body_normal.png",
    "/images/textures/Katheryn.001_body_roughness.png",
    "/images/textures/Katheryn.001_body_specular.png",
    "/images/textures/Katheryn.001_eyes_base_color.png"
  ]);

  _.each([
    hairColorText,
    hairAlphaText,
    hairNormalText,
    teethLowerColor,
    teethNormal,
    teethRoughness,
    bodyBase,
    bodyNormal,
    bodyRoughness,
    bodySpecular,
    eyesTexture
  ], t => {
   // console.log(t)
    t.encoding = sRGBEncoding;
    t.flipY = false;
  });

  // bodyNormalTexture.encoding = LinearEncoding;
  // tshirtNormalTexture.encoding = LinearEncoding;
  // teethTexture.encoding = LinearEncoding;
  // hairNormalTexture.encoding = LinearEncoding;

  // useEffect(() => {
  //   const jaw = model.scene.getObjectByName('jaw');
  //   jaw.translateY(jaw.position.y*0.3);
  //   jaw.translateY(1000);
  // }, []);
  

  model.scene.traverse(node => {
    if (node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {

     // console.log(node)

      // node.castShadow = true;
      // node.receiveShadow = true;
      // node.frustumCulled = false;


      if (node.name.includes("HG_Eyes001")) {
        // node.material = new MeshStandardMaterial();
        // node.material.map = eyesTexture;
        node.material.envMapIntensity = 1;
        node.material.color.setHex(0xF5D7B6);
      }


      if (node.name.includes("Haircards")) {
        //  node.material = new MeshStandardMaterial();
        //  node.material.map = hairColorText;
        //  node.material.alphaMap = hairAlphaText;
        //  node.material.normalMap = hairNormalText;

         //node.material.transparent = false;
         //node.material.side = 2;
         //node.material.color.setHex(0xfbe7a1);
        
         node.material.envMapIntensity = 1;
         // node.material.roughnessMap = hairRoughnessTexture;
        // node.material.normalMap = hairNormalTexture;
        // node.material.alphaMap = hairAlphaTexture;
        // node.material.aoMap = hairAOtexture

      

      }


      if (node.name.includes("HG_Haircap_Brows")) {
        // node.material = new MeshStandardMaterial();
        // node.material.map = haircapTexture;
      }

      if (node.name.includes("HG_Haircap_Eyelashes")) {
        // node.material = new MeshStandardMaterial();
        // node.material.map = haircapTexture;
      }


      if (node.name.includes("HG_Sweater_1_Female002")) {
        // node.material = new MeshStandardMaterial();
        // node.material.map = tshirtDiffuseTexture;
        // node.material.roughnessMap = tshirtRoughnessTexture;
        // node.material.normalMap = tshirtNormalTexture;
      }


      /* ******************** Mesh con materiali ************ */
      if (node.name.includes("HG_Body001")) {

      node.material.color.setHex(0xF5D7B6);
      node.material.envMapIntensity = 1.2;
      node.material.normalScale = new Vector2(0.6, 0.6);
       

        // node.material = new MeshPhysicalMaterial();
        // node.material.map = bodyBase;
        // node.material.normalMap = bodyNormal;
        // node.material.roughnessMap = bodyRoughness;
        // node.material.specularColorMap = bodySpecular;
        
        // node.material.shininess = 60;

        morphTargetDictionaryBody = node.morphTargetDictionary;

        
        // node.material.visible = false;

      }


      if (node.name.includes("HG_TeethUpper001")) {
        // node.receiveShadow = true;
        // node.castShadow = true;
        // node.material.transparent = true;
        // node.material = new MeshStandardMaterial();
        // node.material.roughness = 1;
        // node.material.map = teethTexture;
        // node.material.normalMap = teethNormalTexture;
        // node.material.envMapIntensity = 1;
        // node.material.roughnessMap = teethSpecularTexture;

      }


      if (node.name.includes("HG_TeethLower001")) {  
        
        node.material = new MeshStandardMaterial();
        node.material.map =  teethLowerColor;
        node.normalMap = teethNormal;
        node.roughnessMap = teethRoughness;
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }
    }

  });



  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(model.scene), []);

  useEffect(() => {
    (async () => {
      if (speak === false)
        return;

      let response;
      if(audio){
        response = await makeSpeech(audio);
        resetAudio()
      }
      else if (text){
        response = await makeSpeechFromText(text) 
        setText('')
        callbackTextSent(false)
      }

      startAnimation(response.data)
    })();
  }, [speak]);

  function startAnimation(data){
    let { blendData, filename } = data;

      let newClips = [
        createAnimation(blendData, morphTargetDictionaryBody, 'HG_Body001'),
        createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower001')];

      filename = host + filename;

      setClips(newClips);
      setAudioSource(filename);
  }

  let idleFbx = useFBX('/idle.fbx');
  let { clips: idleClips } = useAnimations(idleFbx.animations);

  idleClips[0].tracks = _.filter(idleClips[0].tracks, track => {
    return track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2");
  });

  idleClips[0].tracks = _.map(idleClips[0].tracks, track => {

    if (track.name.includes("Head")) {
      track.name = "head.quaternion";
    }

    if (track.name.includes("Neck")) {
      track.name = "neck.quaternion";
    }

    if (track.name.includes("Spine")) {
      track.name = "spine2.quaternion";
    }

    return track;

  });

  useEffect(() => {

    let idleClipAction = mixer.clipAction(idleClips[0]);
    idleClipAction.play();

    let blinkClip = createAnimation(blinkData, morphTargetDictionaryBody, 'HG_Body001');
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();

  }, []);

  // Play animation clips when available
  useEffect(() => {

    if (playing === false)
      return;

    _.each(clips, clip => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();

    });

  }, [playing]);
  const modelRef = useRef();

  // Decommentare per abilitare il movimento dell'avatar e centrarlo nella vista
  // useEffect(() => {
  //   function handleKeyDown(event) {
  //     const model = modelRef.current;
  //     if (!model) return;
      
  //     let delta = 0.01;

  //     switch (event.key) {
  //       case 'w': //avanti
  //           model.position.z -= delta;
  //           break;
  //       case 'a': //sinistra
  //           model.position.x -= delta;
  //           break;
  //       case 's': //indietro
  //           model.position.z += delta;
  //           break;
  //       case 'd': //destra
  //           model.position.x += delta;
  //           break;
  //       case 'z': //su
  //           model.position.y += delta;
  //           break;
  //       case 'x': //giu
  //           model.position.y -= delta;
  //           break;
  //       default:
  //           break;
  //     }
  //   // console.log(model.position)

  //   }

  //   window.addEventListener('keydown', handleKeyDown);
  //  // console.log(modelRef.current.position)

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };

  // }, []);

  useFrame((state, delta) => {
    mixer.update(delta);
  });


  return (
    <group name="avatar">
      <primitive ref={modelRef} 
        object={model.scene} 
        dispose={null} 
        position={[0.025,0.25,0.4]}
      />
    </group>
  );
}
async function makeSpeech(blob) {
    const formData = new FormData();
  formData.append('audio', blob);
  return axios.post(host + '/talk-sts', formData);
}

async function makeSpeechFromText(text){
  return await axios.post(host + '/talk-tts', { text });
}

function App() {

  const audioPlayer = useRef();

  const [speak, setSpeak] = useState(false);
  const [text, setText] = useState("");
  const [sending, setSending] = useState(false)
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [blob, setBlob] = useState(null);
  const options = {downloadFileExtension: 'wav'}
  const { startRecording, stopRecording, togglePauseResume, recordingBlob, isRecording, isPaused, recordingTime, mediaRecorder }
    = useAudioRecorder(options);

  useEffect(() => {
    if (recordingBlob) {
      setBlob(recordingBlob);
      setSpeak(true);
    }
  }, [recordingBlob]);

  // End of play
  function playerEnded(e) {
    setAudioSource(null);
    setSpeak(false);
    setPlaying(false);
  }

  // Player is ready
  function playerReady(e) {
    audioPlayer.current.audioEl.current.play();
    setPlaying(true);

  }

  function sendTextToBackend(){
    setSending(true)
    setSpeak(true)
  }

  return (
    <div className="full">
      <div className='inputs-container'>
        <div className='text-area-container'>
          <textarea className='text-area-element' rows={4} type="text" value={text} onChange={(e) => setText(e.target.value)} 
          onKeyDown={(e) => {
            if (e.key === 'Enter' && text !== '') {
              e.preventDefault();
              sendTextToBackend();
            }
          }} />
          <div className='text-button-container'>
            <button className={`text-area-button ${text === '' ? 'text-area-button-disabled': ''}`} onClick={sendTextToBackend} disabled={text === ''}>
              {
                sending ? 
                  <img src='/images/spinner.gif' style={{ maxWidth: '80%', height: 'auto' }} alt="Send"/>
                  : <img src='/images/send.png' style={{ maxWidth: '80%', height: 'auto' }} alt="Send"/>
              }

            </button>
            {/* <div style={STYLES.text}>
              Chiedi
            </div> */}
          </div>
        </div>
        { isRecording ? 
          <div onClick={stopRecording} className='speak-button-container' >
            <img className='button-image' src="/images/pause.png" alt='pause'/>
            <div className='speak-button-text'>Stop</div>
          </div> :
          <div onClick={startRecording} className='speak-button-container'>
            <img className='button-image' src="/images/start.png" alt='start'/>
            <div className='speak-button-text'>Start</div>
          </div>
        }

      </div>

      <ReactAudioPlayer
        src={audioSource}
        ref={audioPlayer}
        onEnded={playerEnded}
        onCanPlayThrough={playerReady}
        
      />
      
      {/* <Stats /> */}
      <Canvas dpr={2} onCreated={(ctx) => {
        ctx.gl.physicallyCorrectLights = true;
      }}
      >


        <Scene 
          speak={speak}
          setSpeak={setSpeak}
          blob={blob}
          setBlob={setBlob}
          text={text}
          setText={setText}
          setSending={setSending}
          setAudioSource={setAudioSource}
          playing={playing}
        />
      </Canvas>
      <Loader dataInterpolation={(p) => `Loading... please wait`} />
    </div>
  )
}

//immagine sfondo avatar
function Bg() {
  const { camera, viewport } = useThree();
  const planeRef = useRef(null);

  const texture = useTexture('/images/officeH.jpg');

  if (!texture.image) {
    return null;
  }

  const textureAspect = texture.image.width / texture.image.height;

  const vFov = camera.fov * (Math.PI / 180);
  const planeHeight = 2 * Math.tan(vFov / 2) * Math.abs(camera.position.z);
  const planeWidth = planeHeight * viewport.aspect;
  const scale_d = 500
  const scale = planeWidth / planeHeight < textureAspect ? 
                [planeHeight * textureAspect*scale_d, planeHeight*scale_d, 1] :
                [planeWidth*scale_d, (planeWidth / textureAspect)*scale_d, 1];
  const position = [0, -30.75, camera.far / 2 * -1]; 
  const rotation = [-Math.PI / 25, 0, 0];

  return (
    <mesh position={position} scale={scale} ref={planeRef} rotation={rotation}>
      <planeBufferGeometry args={[1, 1]} />
      <meshBasicMaterial map={texture} attach="material" />
    </mesh>
  );

}

function Scene({ speak, setSpeak, blob, setBlob, text, setText, setSending, setAudioSource, playing }) {
  const { viewport, camera } = useThree();

  useEffect(() => {
    if (camera.isPerspectiveCamera) {
      camera.fov = 70;
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.near = 0.1;
      camera.far = 800;
      camera.zoom= 1;
      camera.position.set(0, 1.8, 1);
      camera.updateProjectionMatrix();
      camera.lookAt(new THREE.Vector3(0, 1.5, -1))
    }
  }, [camera]);

  return(
    <>
      {/* <OrthographicCamera
          makeDefault
          zoom={40}
          position={[0, 1.65, 1]}
          onUpdate={camera => camera.updateProjectionMatrix()}
        /> */}

        {/* <OrbitControls
          target={[0, 1.65, 0]}
        /> */}

        {/*<FloorPlane/>*/}
        <Suspense fallback={null}>
          <Environment background={false} files="/images/photo_studio_loft_hall_1k.hdr" />
        </Suspense>

      {/* 
        <Suspense fallback={null}>
          <Bg />
        </Suspense>
      */}
        <Suspense fallback={null}>


          <Avatar
            avatar_url="/00_MH_05.glb"
            speak={speak}
            setSpeak={setSpeak}
            audio={blob}
            resetAudio={() => setBlob(null)}
            text={text}
            setText={setText}
            callbackTextSent={setSending}
            setAudioSource={setAudioSource}
            playing={playing}
          />
        </Suspense>
    </>
  )
}

export default App;
